import { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import SendIcon from "@mui/icons-material/Send";

// Zoho REST APIs uses the OAuth 2.0 protocol to authorize
// What is OAuth 2.0 protocol?

// since my domain is .com -- childokay.com
// hence, use https://accounts.zoho.com/

// Zoho Books API Credentials
const ORG_ID = "60039151059";
const ACCESS_TOKEN = "1000.a2ed9a57a10ce204d0bf1f9c8991303b.b70f513c2ad27babe2f0dc7d1fccad93";
// .in vs .com
const BASE_URL = "https://www.zohoapis.in/books/v3";
const savePath = "bill_downloaded.pdf";

const CLIENT_ID = "1000.QJ62VQFJTXDKBDMLZP9OLVE7PT8J2U";
const CLIENT_SECRET = "ccf9afe5fe3605939f8c2efe64bdc6980fa96ffda1";

const CODE = "1000.e1e84e3a528f396040019fae0d39dc7d.c7d5a6c39f121d3345f95c5e42bc241d";

export default function ChatApp() {
  const [messages, setMessages] = useState([]);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);

  // using nodejs = fs. else blob like this .. hmmm
  const handleSend = async () => {
    if (!query.trim()) return;
    
    const userMessage = { role: "user", content: query };
    setMessages([...messages, userMessage]);
    setQuery("");
    setLoading(true);
    
    try {
      // const response = await fetch("https://api.example.com/chat", {
      //   method: "POST",
      //   headers: { "Content-Type": "application/json" },
      //   body: JSON.stringify({ query })
      // });

      // const billId = userMessage.content;
      const billId = "1001125";
      // console.log(billId, "billId")
      // ?organization_id=${ORG_ID}
      const url = `https://cors-anywhere.herokuapp.com/${BASE_URL}/bills/${billId}`;
      const headers = {
          // Authorization: `Zoho-oauthtoken ${ACCESS_TOKEN}`,
          Authorization: `Zoho-oauthtoken ${ACCESS_TOKEN}`,
          'X-com-zoho-invoice-organizationid': `${ORG_ID}`,
          // Accept: "application/pdf"
      };


      // DONE
      // fetch(`https://cors-anywhere.herokuapp.com/https://accounts.zoho.in/oauth/v2/token?scope=ZohoInvoice.fullaccess.all&code=${CODE}&client_id=${CLIENT_ID}&client_secret=${CLIENT_SECRET}&redirect_uri=https://cors-anywhere.herokuapp.com/${BASE_URL}/bills&grant_type=authorization_code`, { method: "POST" })
      // .then(res => res.json())
      // .then(res => console.log(res, "auth response"));

    //   {
    //     "access_token": "1000.a2ed9a57a10ce204d0bf1f9c8991303b.b70f513c2ad27babe2f0dc7d1fccad93",
    //     "refresh_token": "1000.fc8a62288a0e6e552dcb25bb760b63cf.b6d0f1cddff3f2ec0022dd46e0d92603",
    //     "scope": "ZohoInvoice.fullaccess.all",
    //     "api_domain": "https://www.zohoapis.in",
    //     "token_type": "Bearer",
    //     "expires_in": 3600
    // }

      // blob (to handle binary data) vs arrayBuffer
      const response = await fetch(url, { headers, responseType: "blob" });
      
      // Create a Blob from the PDF data
      const blob = new Blob([response.data], { type: "application/pdf" });
      // Create a URL for the Blob
      const pdfUrl = window.URL.createObjectURL(blob);

      // Create a temporary download link
      const a = document.createElement("a");
      a.href = pdfUrl;
      a.download = `bill_${billId}.pdf`; // Set file name
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      console.log(`✅ Bill PDF downloaded successfully: ${savePath}`);

      const data = await response.json();
      
      const botMessage = { role: "bot", content: data.response };
      setMessages([...messages, userMessage, botMessage]);

      } catch (error) {
          console.error("❌ Error downloading bill PDF:", error.response ? error.response.data : error.message);
    }

    setLoading(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSend();
    }
  };

  // console.log(messages, 'messages');

  // max-w-2xl 
  return (
    // p-4
    <div className=" mx-auto h-screen flex items-center">
      <Card className="pb-4 shadow-lg w-full h-full flex flex-col">
        <CardContent className="space-y-4 flex-grow flex flex-col">
        {/* border rounded-lg bg-gray-50 */}
          <div className="h-[80vh] overflow-y-auto">
            {messages.map((msg, index) => (
              <div key={index} className={`p-2 my-4 rounded-lg ${msg.role === "user" ? "bg-blue-100 text-right" : "bg-gray-200 text-left"}`}>{msg.content}</div>
            ))}
            {loading && <CircularProgress size={24} className="text-gray-500" />}
          </div>
        </CardContent>
        <div className="flex gap-2 m-2 border-t mt-auto">
          <TextField
            value={query}
            onKeyDown={handleKeyDown} 
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Ask something..."
            fullWidth
          />
          <Button onClick={handleSend} disabled={loading} variant="contained" color="primary">
            <SendIcon className="w-5 h-5" />
          </Button>
        </div>
      </Card>
    </div>
  );
}
