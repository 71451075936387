import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import PDFHoverViewer from './PDFHoverViewer';
// import reportWebVitals from './reportWebVitals';
// import PDFMozilla from './PDFMozilla';
import ZohoChat from './ZohoChat';

// rm -rf node_modules package-lock.json or manually delete
// npm cache clean --force
// npm install

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <PDFHoverViewer /> */}
    <ZohoChat />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();


// const options = {
//   method: 'GET',
//   headers: {
//     'X-com-zoho-invoice-organizationid': '10234695',
//     Authorization: 'Zoho-oauthtoken 1000.41d9xxxxxxxxxxxxxxxxxxxxxxxxc2d1.8fccxxxxxxxxxxxxxxxxxxxxxxxx125f'
//   }
// };

// fetch('https://www.zohoapis.com/invoice/v3/invoices', options)
//   .then(response => response.json())
//   .then(response => console.log(response))
//   .catch(err => console.error(err));